import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Footer } from "./components/footer";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      {/* <BrowserRouter>
        <Navigation />
        <Header data={landingPageData.Header} ></Header>
        <Routes>
          <Route exact path='/home' element={<Features data={landingPageData.Features} />} />
          <Route exact path='/services' element={<Services data={landingPageData.Services} />} />
          <Route exact path='/faq' element={<Features data={landingPageData.Features} />} />
          <Route exact path='/testimonials' element={<Testimonials data={landingPageData.Testimonials} />} />
          <Route exact path='/about' element={<About data={landingPageData.About} />} />
          <Route exact path='/team' element={<Gallery data={landingPageData.Gallery} />} />
          <Route exact path='/contact' element={<Contact data={landingPageData.Contact} />} />
        </Routes>
      </BrowserRouter> */}
      <Header data={landingPageData.Header} />
      <Services data={landingPageData.Services} />
      <About data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />
      <Footer data={landingPageData.Contact} />
    </div>
  );
};

export default App;
