import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            <br></br>
            <div className="about-text">
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <div className="col-md-6">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.pa6ragraph : "loading..."}</p>
              <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph4 : "loading..."}</p>
              <p>{props.data ? props.data.paragraph5 : "loading..."}</p>
            </div>
          </div>
          <p>We are authorized agents by CGFNS, with Agent ID 85092.</p>
          <img
            className="cgfns-logo"
            alt="HappyPath nurses immigration to NZ"
            src="../img/logo-green.png"
          ></img>
        </div>
      </div>
      <br></br>
      <center>
        {" "}
        <h3>
          Welcome to the HappyPath family - a 100% New Zealand owned and
          registered business.
        </h3>
      </center>
    </div>
  );
};
