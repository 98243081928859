import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="whatsapp-contact">
              <a
                href="https://api.whatsapp.com/send?phone=+64204988440&amp;text=Hi%2C%20I%20want%20to%20know%20more%20about%20the%20following"
                target="_blank"
              >
                <img
                  src="../img/whatsapp-image.png"
                  alt="happypath immigration consultant"
                ></img>
              </a>
            </div>
            <div className="row">
              <div className="intro-text">
                <h1>{props.data ? props.data.title : "Loading"}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
