import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setContactState] = useState(initialState);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setContactState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setContactState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm(
        "service_l700qel",
        "template_868rinw",
        e.target,
        "V7FOzopxFgtesjvBJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          setShowSuccessMessage(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          {showSuccessMessage == true ? (
            <div>
              <h3>
                Thank you for contacting us. We will reply you as soon as we
                can.{" "}
              </h3>
            </div>
          ) : (
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contact Us</h2>
                  <h3>Get in Touch with HappyPath Immigration Consultant</h3>
                  <p>
                    Have questions about immigration opportunities for nurses in
                    New Zealand? Interested in learning more about our services?
                    We're here to help! Feel free to contact us with your
                    inquiries, and our dedicated team will provide you with the
                    information you need to start your journey towards a
                    rewarding career in New Zealand's healthcare sector. Whether
                    you're a nurse candidate or a healthcare employer, we're
                    excited to assist you every step of the way.
                  </p>
                </div>
                <form name="sentMessage" onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Name"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      onChange={handleChange}
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div
                    className="g-recaptcha"
                    data-sitekey="6LeOoNInAAAAAIiSDa4sVpsnYScYFVfEyD0KrUrC"
                  ></div>
                  <button type="submit" className="btn btn-custom btn-lg">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          )}
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <a href={"tel:+64204988440"}>
                  {props.data ? props.data.phone : "loading"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <a href="mailto:info@happypath.co.nz">info@happypath.co.nz</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
