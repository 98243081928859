import React from "react";

export const Footer = (props) => {
    return (
        <div id="footer">
            <div className="col-md-12">
                <div className="row">
                    <div className="social">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/happypath.nz" target="_blank">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/happypath.nz" target="_blank">
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/happypath.nz" target="_blank">
                                    <i className="fa fa-youtube"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container text-center">
                <p>
                    &copy; 2023 HappyPath{" "}
                    <a href="https://www.happypath.co.nz" rel="nofollow">
                        HappyPath
                    </a>
                </p>
            </div>
        </div>);
};
